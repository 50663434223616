.dropdown-toggle::after {
  display: none !important;
}

.template-dropdown :hover {
  background-color: transparent;
}

@media print {
  body {
    visibility: hidden;
  }

  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }

  @page {
    size: auto;
    margin: 0mm;
  }
}

.anticon svg {
  color: #4b5675;
}

/* .ant-picker-input input {
  color: #fff !important;
} */

.ant-picker-input input::placeholder {
  color: #4b5675 !important;
}

@media (max-width: 992px) {
  .payment-layout-img {
    display: none;
  }
}

.activated-tag {
  position: absolute;
  top: 2rem;
  left: -3rem;
  transform: rotate(-45deg);
  z-index: 1;
  padding: 0.5rem 4rem;
}

.activated-tag-url {
  position: absolute;
  top: 2rem;
  right: -3rem;
  transform: rotate(45deg);
  z-index: 1;
  padding: 0.5rem 4rem;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #3e97ff !important;
}

.word-break {
  word-break: break-all;
}